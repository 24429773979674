@mixin pill-btn--outline($color, $bg: #fff, $padding: $pad--min, $elongate: 2, $border-width: 1px) {
	border-radius: 1000px;
	border: $border-width solid $color;
	color: $color;
	padding: $padding $padding * $elongate;
	text-align: center;
	background-color: $bg;
	transition: 0.5s;
	cursor: pointer;
	@include ensureLinkNoUnderline();

	&:hover {
		background-color: $color;
		color: $bg;
	}
	@content;
}

@mixin pill-btn--fill($bg, $color: #fff, $bg-hover: false, $padding: $pad--min, $elongate: 2) {
	color: $color;
	background-color: $bg;
	padding: $padding $padding * $elongate;
	border-radius: 1000px;
	border: none;
	transition: background-color 0.5s;
	text-align: center;
	cursor: pointer;
	@include ensureLinkNoUnderline();
	&:hover {
		@if ($bg-hover) {
			background-color: $bg-hover;
		} @else {
			background-color: darken($bg, 5%);
		}
	}
	@content;
}

@mixin account-edit-btn($icon: true) {
	border: 1px solid $checkout-border-grey;
	background-color: #fff;
	padding: 5px;
	font-weight: $sf-weight--med;
	position: relative;
	border-radius: 5px;
	cursor: pointer;
	display: inline-block;
	transition: all 0.5s;
	color: $sf-grey--light !important;

	@if $icon {
		width: 40px;
		height: 40px;
		svg {
			width: calc(100% - 20px);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			.st0 {
				fill: $sf-grey--light;
				transition: all 0.5s;
			}
		}
	}

	&:hover {
		color: $sf-green !important;
		box-shadow: 0px 0px 10px 0px rgba($sf-green, 0.75);
		@if $icon {
			svg {
				.st0 {
					fill: $sf-green;
				}
			}
		}
	}
	@content;
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px; // Optional: Add padding to prevent collapse

	i,
	span {
		font-size: 30px; // Adjust as needed
		display: inline-block;
	}
}
