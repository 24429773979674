@mixin notification-message($border-left: $error-border) {
	border-left: $border-left;
	padding: $pad--min;
	margin: $pad--min 0;
	overflow: hidden;
	text-align: left;
	font-size: 15px;
	line-height: 20px;
	color: $sf-font-grey;

	p {
		margin-bottom: 0 !important;
	}
}
