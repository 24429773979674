//SF TODO: TIDY (NEILS CODE)
.theme-screamingfrog {
	.thankyou-page {
		padding: 50px 0;

		.view_invoice {
			a {
				font-weight: 600;
				display: inline-block;
				margin-top: $pad--min;
				@include pill-btn--fill($sf-blue--light, #fff!important, $sf-blue--dark);
			}
		}
	}

	.thankyou-page p.woocommerce-notice,
	.thankyou-page h2.woocommerce-order-details__title,
	.thankyou-page .woocommerce-order-details header h2,
	.thankyou-page .woocommerce-customer-details h2.woocommerce-column__title {
		font-weight: 600;
		font-size: 16px;
		margin-bottom: 10px !important;
	}

	.thankyou-page .woocommerce-order-overview.order_details {
		margin: 0 0 35px !important;
		border: 1px solid #e1e1e1;
		display: inline-flex;
		margin: 0;
		padding: 0;
		background-color: #f9f9f9;
	}

	.thankyou-page .woocommerce-order-overview.order_details li {
		margin: 0;
		padding: 15px 25px;
		font-size: 14px;
		font-weight: 600;
		text-transform: none;
		color: #000;
	}

	.thankyou-page .woocommerce-order-overview.order_details li strong {
		display: block;
		border-top: 1px solid #e1e1e1;
		padding-top: 5px;
		margin-top: 5px;
		font-size: 14px;
		font-weight: 400;
	}

	.thankyou-page p {
		margin-bottom: 20px;
	}

	.thankyou-page table.shop_table.woocommerce-table--order-details {
		display: block;
	}

	.thankyou-page table.shop_table.woocommerce-table--order-details tfoot,
	.thankyou-page table.shop_table.woocommerce-table--order-details tbody,
	.thankyou-page table.shop_table.woocommerce-table--order-details thead {
		display: flex;
		flex-direction: column;
	}

	.thankyou-page table.shop_table.woocommerce-table--order-details tr {
		width: 100%;
		display: table;
	}

	.thankyou-page table.shop_table.woocommerce-table--order-details tr th,
	.thankyou-page table.shop_table.woocommerce-table--order-details tr td {
		width: 66%;
	}

	.thankyou-page table.shop_table.woocommerce-table--order-details tfoot tr:nth-child(3) {
		order: 5;
	}

	@media (max-width: 600px) {
		.thankyou-page table.shop_table.licence-list-table,
		.thankyou-page table.shop_table.licence-list-table td,
		.thankyou-page table.shop_table.licence-list-table tbody {
			display: block;
			border: none !important;
			width: 100%;
			text-align: right;
			white-space: normal;
		}
		.thankyou-page table.shop_table.licence-list-table tr:not(:last-child) {
			margin-bottom: 10px;
		}
		.thankyou-page table.shop_table.licence-list-table tr {
			display: block;
			border: 1px solid rgba(0, 0, 0, 0.1) !important;
			width: 100%;
		}
		.thankyou-page table.shop_table.licence-list-table thead {
			display: none;
		}
		.thankyou-page table.shop_table.licence-list-table td:before {
			font-weight: $sf-weight--heavy;
			float: left;
			margin-right: 5px;
		}
		.thankyou-page table.shop_table.licence-list-table td:nth-child(1):before {
			content: "Username: ";
		}
		.thankyou-page table.shop_table.licence-list-table td:nth-child(2):before {
			content: "Licence Key: ";
		}
		.thankyou-page table.shop_table.licence-list-table td:nth-child(3):before {
			content: "Product: ";
		}
		.thankyou-page table.shop_table.licence-list-table td:nth-child(4):before {
			content: "Expires: ";
		}
	}

	.thankyou-page table.shop_table,
	.thankyou-page table.shop_table td,
	.thankyou-page table.shop_table th {
		border: 0;
		border-collapse: collapse;
		word-break: normal;
		border-radius: 0;
	}

	.thankyou-page table.shop_table td,
	.thankyou-page table.shop_table th {
		border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
		border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
		border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
	}
	.thankyou-page table.shop_table {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
	}

	.thankyou-page table.shop_table thead td,
	.thankyou-page table.shop_table thead th {
		background-color: #f5f5f5;
		font-size: 16px;
		color: $sf-font-grey;
		padding: 10px 15px;
		margin-bottom: 15px;
	}

	.thankyou-page table.shop_table tbody td,
	.thankyou-page table.shop_table tbody th {
		font-size: 16px;
		color: $sf-font-grey;
		padding: 10px 15px;
	}

	.thankyou-page table.shop_table tbody tr + tr td,
	.thankyou-page table.shop_table tbody tr + tr th {
		border-top: 1px solid #d6d6d6;
	}

	.thankyou-page table.shop_table tfoot {
		font-size: 18px;
		color: $sf-font-grey;
	}

	.thankyou-page table.shop_table tfoot th,
	.thankyou-page table.shop_table tfoot td {
		border: 0;
	}

	.thankyou-page table.shop_table tfoot th {
		background-color: #f5f5f5;
		padding: 10px 15px;
		font-weight: 400;
		font-size: 16px;
		vertical-align: middle;
	}

	.thankyou-page table.shop_table tfoot tr:last-of-type td,
	.thankyou-page table.shop_table tfoot tr:last-of-type th {
		font-weight: $sf-weight--heavy;
	}

	.thankyou-page table.shop_table tfoot td {
		color: $sf-font-grey;
		padding: 15px;
	}

	.thankyou-page table.shop_table tfoot td {
		font-weight: 400;
	}

	.thankyou-page table.shop_table td.subscription-actions.order-actions a.button {
		background-color: #75b42c !important;
	}

	.thankyou-page table.shop_table td.subscription-actions.order-actions a.button:hover {
		background-color: #599f08 !important;
	}

	.thankyou-page .woocommerce-customer-details address {
		padding: 20px;
		line-height: 24px;
	}

	.thankyou-page .woocommerce-customer-details address .woocommerce-customer-details--phone,
	.thankyou-page .woocommerce-customer-details address .woocommerce-customer-details--email {
		padding-top: 15px;
	}

	.thankyou-page .woocommerce-customer-details .woocommerce-customer-details--phone:before,
	.thankyou-page .woocommerce-customer-details address .woocommerce-customer-details--email:before {
		color: #599f08;
	}

	//NEW
	.thankyou-page .woocommerce-order-details {
		p {
			a {
				color: $sushi;
				&:hover {
					text-decoration: underline;
				}
			}
		}

		h2.woocommerce-order-details__title {
			margin: 20px 0 $pad--min !important;
		}

		table {
			width: 100%;
			td,
			th {
				text-align: left;
			}
		}
	}
}
