///*=====================================================================</editor-fold>=
// VARS
///=====================================================================<editor-fold>=*/

///***********************************</editor-fold>*/
// GLOBAL
///**********************************<editor-fold>*/

$pad--min: 15px;
$pad--small: 30px;
$pad--large: 60px;
$sf-border: 1px solid $sf-border--grey;
$sf-font-family: "museo-sans", sans-serif;

$sf-font-size--x-small: 12px;
$sf-font-size--small: 14px;
$sf-font-size--standard: 16px;
$sf-font-size--large: 18px;
$sf-font-size--x-large: 22px;
$sf-font-size--h1: 35px;
$sf-font-size--h2: 32px;
$sf-font-size--h3: 24px;
$sf-font-size--h4: $sf-font-size--x-large;
$sf-font-size--h5: 20px;
$sf-font-size--h6: $sf-font-size--large;

$sf-paragraph-max-w: 800px;

$sf-wrap--max-w: 1350px;

$sf-weight--light: 300;
$sf-weight--med: 500;
$sf-weight--heavy: 700;
$sf-weight--x-heavy: 700;

$sf-min-screen-size: 320px;

$sf-max-p-width: 1000px;

$block-spacing-v--sm: $pad--small;
$block-spacing-v--md: $pad--large;

///***********************************</editor-fold>*/
// FONT AWESOME
///**********************************<editor-fold>*/
$fa-left-chevron: "\f053";
$fa-right-chevron: "\f054";

///***********************************</editor-fold>*/
// ERRORS
///**********************************<editor-fold>*/
$error-border: 3px solid $sf-color--error;
$success-border: 3px solid $sf-color--success;

///***********************************</editor-fold>*/
// CHECKOUT
///**********************************<editor-fold>*/
$checkout-light-grey: #f9f9f9;
$checkout-border-grey: #aaaaaa;
$checkout-tab-grey: #e6e6e6;

$checkout-border: 1px solid $checkout-border-grey;

$checkout-icon--w: 30px;

$checkout-label-pad: 5px;

$checkout-breakpoint: 750px;

///***********************************</editor-fold>*/
// CURRENCY CONVERSION AND QUANTITY INPUT
///**********************************<editor-fold>*/
$currency-and-quantity-inputs--pad--v: 7px;
$currency-and-quantity-inputs--pad--h: 14px;
$currency-and-quantity-inputs--font-size: 18px;
$currency-and-quantity-inputs--font-weight: 400;
$currency-and-quantity-inputs--line-height: 25px;
$currency-and-quantity-inputs--color: $scorpion;

///***********************************</editor-fold>*/
// Shadows
///**********************************<editor-fold>*/

$sf-block-shadow: 0 6px 15px 0 rgba(#000, 0.07), 0 11px 30px 0 rgba(#000, 0.07);
$sf-block-shadow--md: 0 6px 30px 0 rgba(#000, 0.07), 0 11px 60px 0 rgba(#000, 0.07);
$sf-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0 6px 0 rgba(0, 0, 0, 0.1),
	0 2px 2px -2px rgba(0, 0, 0, 0.15);
$sf-shadow--green: 0 1px 4px 0 rgba($sf-green, 1), 0 0 6px 0 rgba($sf-green, 1),
	0 2px 2px -2px rgba($sf-green, 1);
$sf-bg-shadow--to-right: linear-gradient(to right, #f7f7f7 0%, #fff 12%);
$sf-bg-shadow--to-left: linear-gradient(to left, #f7f7f7 0%, #fff 12%);
