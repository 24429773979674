.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output,
.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
	border: none;
	@include notification-message(3px solid #ff6565!important);
}

.wpcf7 form.sent .wpcf7-response-output {
	border: none;
	@include notification-message($success-border);
}
