$reg-login-switch--w: 350px;
$reg-login-switch--w--xl: 500px;
$reg-login--md: 750px;

@mixin LoginRegisterSwitchTextSwap($loginText: false, $registerText: false) {
	position: relative;
	&:before,
	&:after {
		display: block;
		height: 0;
		width: 100%;
		opacity: 0;
		transition: opacity 1s;
		overflow: hidden;
		position: relative;
	}
	@if ($loginText) {
		&:before {
			content: $loginText;
			[data-access="register"] & {
				opacity: 1;
				height: auto;
			}
		}
	}
	@if ($registerText) {
		&:after {
			content: $registerText;
			[data-access="login"] & {
				opacity: 1;
				height: auto;
			}
		}
	}
}

.sfwc-access-account {
	background-color: #fff;
	opacity: 0;
	transition: opacity 0.5s;
	&[data-access="login"],
	&[data-access="register"] {
		opacity: 1;
	}

	&__panel {
		margin: 30px auto;
	}

	&__tabs {
		display: flex;
		justify-content: space-between;

		button {
			width: 50%;
			padding: $pad--min;
			background-color: $checkout-tab-grey;
			@include p($color: $sf-font-grey, $font-weight: $sf-weight--med);
			border: none;
			transition: all 0.5s;
			cursor: pointer;
			&:hover {
				background-color: darken($checkout-tab-grey, 5%);
			}

			@each $access in ("login", "register") {
				[data-access="#{$access}"] & {
					&[data-change-access="#{$access}"] {
						background-color: $checkout-light-grey;
						color: $sushi;
						pointer-events: none;
					}
				}
			}
		}
	}

	&__form {
		background-color: $checkout-light-grey;
		overflow: hidden;
		$checkout-form-transition-period: 1s;
		transition: all $checkout-form-transition-period;
		position: relative;

		form {
			width: 100%;
			transition: opacity 0.5s;
			button[type="submit"],
			button.pseudo-submit {
				@include p(#fff);
				@include pill-btn--fill($sf-green, #fff, false, 10px, 3);
			}

			.form-row {
				@include checkoutFormRow();
			}

			@include respond-to-min($reg-login--md) {
				width: 300px;
				@include respond-to-min(850px) {
					width: 400px;
					@include respond-to-min(950px) {
						width: 500px;
					}
				}
			}
		}

		@include respond-to-max($reg-login--md) {
			h2 {
				display: none; // hide duplicate title under tabs on mobile
			}

			padding: $pad--small $pad--min $pad--min;
			&--login {
				[data-access="register"] & {
					display: none;
				}
			}
			&--register {
				[data-access="login"] & {
					display: none;
				}
			}
		}
	}

	&__switch {
		width: $reg-login-switch--w;
		background-color: $sushi;
		display: none;
		padding: $pad--min;

		h2 {
			@include h2($color: #fff) {
				margin-bottom: $pad--min;
				width: 100%;
				text-align: center;
			}
			@include LoginRegisterSwitchTextSwap("Already Signed Up?", "Need An Account?");
		}

		p {
			@include p(#fff);
			margin-bottom: $pad--min;
			text-align: center;
			width: 100%;
			padding: 0 $pad--min;
			@include LoginRegisterSwitchTextSwap(
				"Log in to access your licence keys & invoices.",
				"Registering will allow you to access your licence keys & invoices at anytime."
			);
		}

		button {
			@include LoginRegisterSwitchTextSwap("Log In", "Register");
			@include pill-btn--outline(#fff, $sushi, 10px, 3);
			@include p(#fff);
			margin-top: $pad--min * 2;
		}
	}

	@include respond-to-min($reg-login--md) {
		&__panel {
			display: flex;
		}
		&__tabs {
			display: none;
		}
		&__form {
			width: calc(100% - #{$reg-login-switch--w});
			&--login {
				[data-access="register"] & {
					width: 0;
					form {
						height: 0;
						opacity: 0;
					}
				}
			}
			&--register {
				[data-access="login"] & {
					width: 0;
					form {
						height: 0;
						opacity: 0;
					}
				}
			}
			form {
				padding: 0;
				margin: $pad--min * 2 auto;
			}
		}
		&__switch {
			display: flex;
			justify-content: center;
			justify-items: center;
			align-content: center;
			align-items: center;
			flex-direction: column;
		}
		@include respond-to-min(xl) {
			&__form {
				width: calc(100% - #{$reg-login-switch--w--xl});
			}
			&__switch {
				width: $reg-login-switch--w--xl;
				p {
					width: 80%;
				}
			}
		}
	}
}

.sfwc-login-form-footer {
	margin-top: $pad--small;
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: center;

	&__option {
		display: block;
		cursor: pointer; // indicate it's clickable
		@include p();
		a {
			@include link($color: $sf-font-grey, $hover-color: $sf-font-grey, $underline: "on-hover");
		}

		span {
			display: block;
			&:before {
				display: inline-block;
				margin-right: $pad--min;
				@include respond-to-max(350px) {
					margin-right: 5px; // allow space on super small screens
				}
			}
		}

		&--remember-me {
			span {
				&:before {
					transition: all 0.3s; // animate the icon change
					@include remixicon($ri-checkbox-blank-line); // checkbox icon (unchecked)
				}
				&:hover:before {
					@include remixicon($ri-checkbox-line); // checkbox icon (checked)
				}
			}
			input {
				display: none;
				&:checked + span {
					&:before {
						@include remixicon($ri-checkbox-fill); // checkbox icon (checked - fill style)
					}
					&:hover:before {
						opacity: 0.5; // reduce opacity on hover to indicate it's clickable
					}
				}
			}
		}

		&--lost-password {
			span {
				&:before {
					@include remixicon($ri-lock-fill); // lock icon (locked)
				}
				&:hover {
					&:before {
						@include remixicon($ri-lock-unlock-fill); // lock icon (unlocked)
					}
				}
			}
		}
	}
}
