@import "../../../config/config";

$account-table-border-color: #dcdcdc;
$account-table-border: 1px solid $account-table-border-color;
$account-table-row-highlight-bg-color: rgba(0, 0, 0, 0.025);
$account-table-keys: "Order ID", "Purchase Date", "Username", "License Key", "product", "Status",
	"Expires", "Assigned User";
$account-table-bp--lg: 1130px;

///*=====================================================================</editor-fold>=
// ACCOUNT
///=====================================================================<editor-fold>=*/
.account-wrap--inner_licence > *,
.account-wrap--inner_orders > * {
	margin-right: 0;
}
.account-wrap--inner_licence {
	> p {
		@include p();
		margin: $pad--min 0;
	}
	> a {
		@include pill-btn--fill($sf-orange--light, #fff, $sf-orange--dark);
		width: 100%;
		max-width: 300px;
	}
}
@include respond-to-min(767px) {
	.wrap-account {
		table.shop_table {
			tr {
				th,
				td {
					&:not(:last-child) {
						border-right: $account-table-border !important;
					}
				}
			}
		}
	}
}

.account-wrap--inner_licence {
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	flex-wrap: wrap;

	.your-licence-label {
		font-size: 24px;
		margin-right: 15px;
		color: $sf-font-grey;
		display: inline-block;
		font-weight: $sf-weight--light;
	}

	.assign-user {
		&-message {
			&--error {
				@include notification-message;
			}
			&--success {
				@include notification-message($success-border);
			}
		}
		&:after {
			content: "";
			clear: both;
			display: block;
		}

		&--expired {
			&:before {
				padding: 0 !important;
			}
		}

		&__form {
			padding: 5px 0 0;
			float: right;
			width: 100%;
			@include respond-to-max($account-table-bp--lg) {
				max-width: 300px;
			}

			&--error {
				&:before {
					display: block;
					content: "Invalid email address";
					color: $bittersweet;
				}
				input[type="text"] {
					border-color: $bittersweet !important;
				}
			}
		}

		&__input[type="email"] {
			border: 1px solid $checkout-border-grey;
			display: block;
			width: 100%;
			padding: 5px !important;
			text-align: right !important;
		}

		&__btn {
			font-size: 12px;
			padding: 1px $pad--min;

			border-radius: 1000px;
			border: none;
			color: #fff;
			cursor: pointer;
			float: right;
			font-weight: $sf-weight--heavy;
			transition: all 0.5s;
			margin-top: 5px;
			@include truncate();

			&[disabled] {
				cursor: not-allowed;
				opacity: 0.5;
			}

			&--update {
				margin-right: 5px;
				background-color: #75b42c;
				&:not([disabled]):hover {
					background-color: #599f08;
				}
			}

			&--email {
				background-color: #7180e4;
				&:not([disabled]):hover {
					background-color: #3f52d5;
				}
			}
		}

		@include respond-to-min(500px) {
			&:before {
				padding-right: 10px;
				padding-top: 7px;
			}

			&__form {
				padding: 0;
			}
		}
		@include respond-to-min($account-table-bp--lg) {
			&__btn {
				float: left;
				&--email {
					margin-right: 5px;
				}
				&--update {
					margin-right: 0;
				}
			}

			padding: 0 !important;
			&--expired {
				padding: 0 5px !important;
			}
			&__form {
				padding: 23px 5px 5px;

				&--error {
					padding: 0 5px 5px;
					&:before {
						line-height: 23px;
					}
				}
			}

			&__input[type="email"] {
				text-align: left !important;
			}
		}
	}

	table.wpco_orders {
		margin: 30px 0 20px;
		width: 100%;
		max-width: 100%;
		border: $account-table-border;

		td,
		th {
			color: $sf-font-grey;
			font-size: 14px !important;
			text-align: left;
			padding: $pad--min 5px;
		}

		th {
			font-weight: 900;
		}

		@include respond-to-max($account-table-bp--lg) {
			border: none;
			thead {
				display: none;
			}
			tbody {
				tr {
					border-bottom: 10px solid #fff;

					&:nth-child(even) {
						td {
							background-color: $account-table-row-highlight-bg-color;
						}
					}
				}

				td {
					display: block;
					border: 1px solid $account-table-border;

					&:not(:last-child) {
						border-bottom: none;
					}

					text-align: right;
					width: 100% !important;
					white-space: unset;
					padding: 5px !important;

					&:before {
						float: left;
						font-weight: 900;
					}

					$i: 1;
					@each $key in $account-table-keys {
						&:nth-child(#{$i}) {
							&:before {
								content: "#{$key}: ";
							}
						}
						$i: $i + 1;
					}
				}
			}
		}
	}

	.wpcopaging {
		display: flex;
		align-content: center;
		align-items: center;
		white-space: nowrap;
		flex-wrap: wrap;
		max-width: 100%;
		&__btns {
			display: flex;
			flex-wrap: wrap;
			a:first-child {
				margin-left: 0;
			}
		}
	}

	//purchase more licenses button on sf-dev/licences
	& > div .left > a {
		border-radius: 50px;
		text-align: center;
		color: #fff;
		font-weight: $sf-weight--heavy;
		-webkit-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		width: 100%;
		max-width: 200px;

		@include respond-to-max(500px) {
			order: 1;
			max-width: unset;
			margin-top: 20px;
		}

		padding: 14px 0;
		display: inline-block;
		margin: 0;
		background: #ffbc2b;

		&:hover {
			background: #f2a600;
			color: #fff;
		}
	}
}

///**********************************************************************/
//SF TODO: TIDY:

.account-wrap {
	background: #f8f8f8;
	padding: 70px 0;
}

.account-wrap.profile-wrap {
	background: #fff;
	padding: 140px 0 70px;
}

.account-wrap--inner.account-landing a:not(.checkout_order_view):not(.savepdfinvoicelink) {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 22.75%;
	margin-left: 0%;
	margin-right: 3%;
	background: #fff;
	min-height: 240px;
	display: table;
	padding: 100px 0;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	position: relative;
}

.account-wrap--inner.account-landing a:not(.checkout_order_view):not(.savepdfinvoicelink):before {
	content: "";
	display: table;
}

.account-wrap--inner.account-landing a:not(.checkout_order_view):not(.savepdfinvoicelink):after {
	content: "";
	display: table;
	clear: both;
}

.account-wrap--inner.account-landing
	a:not(.checkout_order_view):not(.savepdfinvoicelink):last-child {
	margin-right: 0%;
}

@media (max-width: 1000px) {
	.account-wrap--inner.account-landing a:not(.checkout_order_view):not(.savepdfinvoicelink) {
		*zoom: 1;
		float: left;
		clear: none;
		text-align: inherit;
		width: 48.5%;
		margin-left: 0%;
		margin-right: 3%;
		margin-bottom: 3%;
	}

	.account-wrap--inner.account-landing a:not(.checkout_order_view):not(.savepdfinvoicelink):before {
		content: "";
		display: table;
	}

	.account-wrap--inner.account-landing a:not(.checkout_order_view):not(.savepdfinvoicelink):after {
		content: "";
		display: table;
		clear: both;
	}

	.account-wrap--inner.account-landing
		a:not(.checkout_order_view):not(.savepdfinvoicelink):nth-child(2n) {
		margin-right: 0%;
		float: right;
	}

	.account-wrap--inner.account-landing
		a:not(.checkout_order_view):not(.savepdfinvoicelink):nth-child(2n + 1) {
		clear: both;
	}
}

@media (max-width: 500px) {
	.account-wrap--inner.account-landing a:not(.checkout_order_view):not(.savepdfinvoicelink),
	.account-wrap--inner.account-landing
		a:not(.checkout_order_view):not(.savepdfinvoicelink):nth-child(2n) {
		float: none;
		width: 100%;
		margin-right: 0;
	}
}

.account-wrap--inner.account-landing a:not(.checkout_order_view):not(.savepdfinvoicelink):before {
	content: "\f178";
	font-family: fontAwesome;
	position: absolute;
	bottom: 50px;
	opacity: 0;
	text-align: center;
	width: 100%;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	font-size: 32px !important;
	color: #75b42c;
}

.account-wrap--inner.account-landing a:not(.checkout_order_view):not(.savepdfinvoicelink) h2 {
	font-size: 22px;
	color: #b7b7b7;
	font-weight: $sf-weight--med;
	text-align: center;
	display: table-cell;
	vertical-align: middle;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	margin: 0 auto;
}

.account-wrap--inner.account-landing a:not(.checkout_order_view):not(.savepdfinvoicelink):hover h2 {
	color: #626262;
	-webkit-transform: translateY(-20px);
	-ms-transform: translateY(-20px);
	transform: translateY(-20px);
}

.account-wrap--inner.account-landing
	a:not(.checkout_order_view):not(.savepdfinvoicelink):hover:before {
	-webkit-transform: translateY(-20px);
	-ms-transform: translateY(-20px);
	transform: translateY(-20px);
	opacity: 1;
}

.account-wrap--inner table.wpco_orders tr td a {
	min-height: 0;
	display: inline-block;
	padding: 0;
}

.account-wrap--inner table.wpco_orders tr td a:before {
	content: "";
	font-family: fontAwesome;
	display: block;
	color: #75b42c;
	cursor: pointer;
}

.account-wrap--inner table.wpco_orders tr td a:before:hover {
	colour: #599f08;
}

.account-wrap--inner table.wpco_orders tr td a.checkout_order_view:before {
	content: "";
}

.account-wrap--inner table.wpco_orders tr td a.savepdfinvoicelink:before {
	content: "";
}

.account-wrap--inner_licence > div h3 {
	// display: none;
}

.account-wrap--inner_licence > div .wpcopaging span.displaying-num {
	float: left;
	display: inline-block;
	width: auto;
	vertical-align: middle;
	margin-right: 20px;
	font-style: normal;
	color: $sf-font-grey;
}

// .account-wrap--inner_licence > div .wpcopaging span.page-numbers {
// 	vertical-align: middle;
// 	// margin-top: 9px;
// }

.account-wrap--inner_licence > div .wpcopaging span {
	border: 0;
	color: #9f9f9e !important;
	display: inline-block;
}

.account-wrap--inner_licence > div .wpcopaging a {
	color: #9f9f9e !important;
	display: inline-block;
}

.account-wrap--inner_licence > div .wpcopaging span.current,
.account-wrap--inner_licence > div .wpcopaging a.current {
	border: 1px solid #f0f0f0;
	font-size: 15px;
	padding: 14px 24px;
	background: #f0f0f0;
	pointer-events: none;
}

.account-wrap--inner_licence > div .wpcopaging span.extend,
.account-wrap--inner_licence > div .wpcopaging a.extend {
	display: none;
}

.account-wrap--inner_licence > div .wpcopaging a {
	border: 1px solid #f0f0f0;
	font-size: 15px;
	padding: 14px 24px;
	margin-right: 15px;
	margin-top: 7.5px;
	margin-bottom: 7.5px;
}

.account-wrap--inner_orders > div {
	padding: 50px;
}

.account-wrap--inner_orders h3 {
	float: left;
	font-size: 18px;
	line-height: 2.5;
	margin-bottom: 0.5em;
}

.account-wrap--inner_orders .savepdfinvoicelink {
	margin-bottom: 15px;
}

@media (max-width: 520px) {
	.account-wrap--inner_orders .savepdfinvoicelink {
		width: 100%;
		display: block;
		max-width: none;
	}
}

.account-wrap--inner_orders tfoot {
	display: none;
}

.account-wrap--inner_orders table.wpco_orderview {
	max-width: 400px;
	float: none;
	clear: both;
	margin-bottom: 20px;
}

.account-wrap--inner_orders table.wpco_orderview tbody tr td {
	padding: 10px 15px !important;
}

.account-wrap--inner_orders table.wpco_orderviewcart td:nth-child(5),
.account-wrap--inner_orders table.wpco_orderviewcart th:nth-child(4),
.account-wrap--inner_orders table.wpco_orderviewcart td:nth-child(6),
.account-wrap--inner_orders table.wpco_orderviewcart th:nth-child(5),
.account-wrap--inner_orders table.wpco_orderviewcart td:nth-child(8),
.account-wrap--inner_orders table.wpco_orderviewcart th:nth-child(7) {
	display: none;
}

@media (max-width: 600px) {
	.account-wrap--inner_orders table.wpco_orders thead,
	.account-wrap--inner_orders table.wpco_orders tbody {
		display: block;
	}

	.account-wrap--inner_orders table.wpco_orders thead tr,
	.account-wrap--inner_orders table.wpco_orders tbody tr {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.account-wrap--inner_orders table.wpco_orders thead tr td,
	.account-wrap--inner_orders table.wpco_orders tbody tr td,
	.account-wrap--inner_orders table.wpco_orders thead tr th,
	.account-wrap--inner_orders table.wpco_orders tbody tr th {
		display: inline-block;
		width: 33.33333333333333%;
		border-width: 1px 1px 1px 0;
	}

	.account-wrap--inner_orders table.wpco_orders thead tr td:nth-child(1),
	.account-wrap--inner_orders table.wpco_orders tbody tr td:nth-child(1),
	.account-wrap--inner_orders table.wpco_orders thead tr th:nth-child(1),
	.account-wrap--inner_orders table.wpco_orders tbody tr th:nth-child(1),
	.account-wrap--inner_orders table.wpco_orders thead tr td:nth-child(6),
	.account-wrap--inner_orders table.wpco_orders tbody tr td:nth-child(6),
	.account-wrap--inner_orders table.wpco_orders thead tr th:nth-child(6),
	.account-wrap--inner_orders table.wpco_orders tbody tr th:nth-child(6),
	.account-wrap--inner_orders table.wpco_orders thead tr td:nth-child(7),
	.account-wrap--inner_orders table.wpco_orders tbody tr td:nth-child(7),
	.account-wrap--inner_orders table.wpco_orders thead tr th:nth-child(7),
	.account-wrap--inner_orders table.wpco_orders tbody tr th:nth-child(7) {
		border-left-width: 1px;
	}

	.account-wrap--inner_orders table.wpco_orders thead tr td:nth-child(6),
	.account-wrap--inner_orders table.wpco_orders tbody tr td:nth-child(6),
	.account-wrap--inner_orders table.wpco_orders thead tr th:nth-child(6),
	.account-wrap--inner_orders table.wpco_orders tbody tr th:nth-child(6) {
		width: 100%;
		border-top-width: 0;
	}

	.account-wrap--inner_orders table.wpco_orders thead tr td:nth-child(2),
	.account-wrap--inner_orders table.wpco_orders tbody tr td:nth-child(2),
	.account-wrap--inner_orders table.wpco_orders thead tr th:nth-child(2),
	.account-wrap--inner_orders table.wpco_orders tbody tr th:nth-child(2),
	.account-wrap--inner_orders table.wpco_orders thead tr td:nth-child(5),
	.account-wrap--inner_orders table.wpco_orders tbody tr td:nth-child(5),
	.account-wrap--inner_orders table.wpco_orders thead tr th:nth-child(5),
	.account-wrap--inner_orders table.wpco_orders tbody tr th:nth-child(5) {
		display: none;
	}

	.account-wrap--inner_orders table.wpco_orders thead tr td:nth-child(7),
	.account-wrap--inner_orders table.wpco_orders tbody tr td:nth-child(7),
	.account-wrap--inner_orders table.wpco_orders thead tr th:nth-child(7),
	.account-wrap--inner_orders table.wpco_orders tbody tr th:nth-child(7),
	.account-wrap--inner_orders table.wpco_orders thead tr td:nth-child(8),
	.account-wrap--inner_orders table.wpco_orders tbody tr td:nth-child(8),
	.account-wrap--inner_orders table.wpco_orders thead tr th:nth-child(8),
	.account-wrap--inner_orders table.wpco_orders tbody tr th:nth-child(8) {
		width: 50%;
		border-top-width: 0;
	}

	.account-wrap--inner_orders table.wpco_orders thead tr th:nth-child(7),
	.account-wrap--inner_orders table.wpco_orders tbody tr th:nth-child(7),
	.account-wrap--inner_orders table.wpco_orders thead tr th:nth-child(8),
	.account-wrap--inner_orders table.wpco_orders tbody tr th:nth-child(8),
	.account-wrap--inner_orders table.wpco_orders thead tr th:nth-child(6),
	.account-wrap--inner_orders table.wpco_orders tbody tr th:nth-child(6) {
		display: none;
	}

	.account-wrap--inner_orders table.wpco_orderviewcart thead,
	.account-wrap--inner_orders table.wpco_orderviewcart tbody {
		display: block;
	}

	.account-wrap--inner_orders table.wpco_orderviewcart thead tr,
	.account-wrap--inner_orders table.wpco_orderviewcart tbody tr {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.account-wrap--inner_orders table.wpco_orderviewcart th,
	.account-wrap--inner_orders table.wpco_orderviewcart td {
		width: 100%;
	}

	.account-wrap--inner_orders table.wpco_orderviewcart td:nth-child(2),
	.account-wrap--inner_orders table.wpco_orderviewcart th:nth-child(1),
	.account-wrap--inner_orders table.wpco_orderviewcart th:nth-child(3),
	.account-wrap--inner_orders table.wpco_orderviewcart th:nth-child(6) {
		display: none;
	}

	.account-wrap--inner_orders table.wpco_orderviewcart td:nth-child(4),
	.account-wrap--inner_orders table.wpco_orderviewcart td:nth-child(7) {
		width: 50%;
	}

	.account-wrap--inner_orders table.wpco_orderviewcart td:nth-child(3),
	.account-wrap--inner_orders table.wpco_orderviewcart td:nth-child(4) {
		border-top-width: 0;
	}

	.account-wrap--inner_orders table.wpco_orderviewcart td:nth-child(7) {
		border-top-width: 0;
		border-left-width: 0;
	}
}

.woocommerce-Address {
	h3 {
		font-size: 24px !important;
		color: $sf-font-grey;
		font-weight: 600;
	}
	address {
		font-size: 16px !important;
		color: $sf-font-grey;
		font-weight: $sf-weight--light;
	}
}
//</editor-fold>
