@import "config/config";
// SF TODO: remove once all instances of usage removed:
@import "vendor/font-awesome";

@import "partials/components/validation/contact-forms";
@import "partials/components/validation/disabled-inputs";

@import "partials/components/sf-message";

@import "partials/base/base";

@import "partials/components/owl-carousel";

@import "partials/base/global";
@import "partials/base/shorthands";

@import "partials/components/col-structure";

@import "partials/components/aspect";

@import "partials/sections/about/about";

@import "partials/sections/blog/blog";

@import "partials/sections/blog/comments";

@import "partials/sections/clients";

@import "partials/sections/contact";

@import "partials/components/map";

@import "partials/components/errors";

@import "partials/components/footer/footer";

@import "partials/sections/home-page/testimonial.scss";

@import "partials/components/graph";

@import "partials/components/tabs/ul-tabs";

@import "partials/components/tabs/contact-tabs";

@import "partials/sections/checkout/responses";

@import "partials/sections/checkout/orders-and-invoices";

@import "partials/sections/services/ppc/ppc";

@import "partials/sections/services/top";

@import "partials/sections/services/contact";

@import "partials/components/navs/product-nav";
@import "partials/components/navs/dropdown-nav";

@import "partials/sections/software/features";

@import "partials/sections/software/spider/clients";

.spider-testimonials {
	padding: 70px 0;
}

.spider-testimonials--single {
	float: left;
	width: 45%;
	max-width: 580px;
	padding: 50px;
	background: #fff;
	position: relative;
}

.spider-testimonials--single:last-child {
	float: right;
}

@media (max-width: 1050px) {
	.spider-testimonials--single {
		*zoom: 1;
		float: left;
		clear: none;
		text-align: inherit;
		width: 48.5%;
		margin-left: 0%;
		margin-right: 3%;
	}

	.spider-testimonials--single:before {
		content: "";
		display: table;
	}

	.spider-testimonials--single:after {
		content: "";
		display: table;
		clear: both;
	}

	.spider-testimonials--single:last-child {
		margin-right: 0%;
	}
}

@media (max-width: 850px) {
	.spider-testimonials--single {
		*zoom: 1;
		text-align: inherit;
		width: 100%;
		margin-left: 0%;
		margin-right: 3%;
		clear: both;
		max-width: 100%;
		float: none;
	}

	.spider-testimonials--single:before {
		content: "";
		display: table;
	}

	.spider-testimonials--single:after {
		content: "";
		display: table;
		clear: both;
	}

	.spider-testimonials--single:last-child {
		margin-right: 0%;
		margin-top: 30px;
	}
}

.spider-testimonials--single:before {
	content: "";
	display: block;
	background: url("../../public/images/left-quote.png") no-repeat;
	width: 107px;
	height: 80px;
	position: absolute;
	left: 30px;
	top: 20px;
}

.spider-testimonials--single > p {
	color: #6d6d6d;
	font-size: 22px;
	line-height: 36px;
	font-weight: $sf-weight--light;
}

.spider-testimonials--single-name p {
	color: $sf-green;
	font-size: 16px;
	margin-top: 20px;
}

@import "partials/sections/software/summary";

@import "partials/sections/software/about";

@import "partials/sections/software/user-guides-and-faq/user-guides";
@import "partials/sections/software/user-guides-and-faq/guide";

@import "partials/sections/software/user-guides-and-faq/list";
@import "partials/sections/software/user-guides-and-faq/answers";

@import "partials/sections/software/user-guides-and-faq/main-blog-structure-adjust";

.spider-mid {
	background: #fff;
	padding: 100px 0;
}

.spider-mid--inner h3 {
	font-size: 24px;
	color: $sf-font-grey;
	text-align: center;
	margin: 0 auto;
	max-width: 780px;
	font-weight: $sf-weight--light;
	line-height: 35px;
}

@import "partials/sections/software/multi-licence-pricing";

@import "partials/sections/software/license-questions";

@import "partials/sections/checkout/cart";
@import "partials/sections/checkout/thank-you";

@import "partials/sections/checkout/wp-checkout-plugin/wp-checkout-plugin";

@import "partials/sections/checkout/steps";

@import "partials/sections/checkout/wp-checkout-plugin/summarybox";

@import "partials/sections/checkout/single-page-checkout";

@import "partials/sections/software/buy-renew/add-to-cart";

@import "partials/components/currency-conversion";

@import "partials/sections/checkout/wp-checkout-plugin/steps";

@import "partials/sections/checkout/wp-checkout-plugin/billing-form";

@import "partials/sections/account/account";

@import "partials/sections/about/timeline";

@import "partials/components/testimonials";

@import "partials/components/btns";
@import "partials/components/brand";
@import "partials/components/hero";
@import "partials/sections/about/vacancy";
@import "partials/components/accordion";

@import "partials/sections/learn-seo/overview";
@import "partials/sections/learn-seo/to-become-global";
.faqs {
	padding: 0 0 25px;
}
@import "partials/components/blog-sticky-sidebar";
@import "partials/components/table-of-contents";
@import "partials/sections/software/support";

@import "partials/sections/account/billing-address";
@import "partials/sections/account/orders-and-invoices";
@import "partials/sections/account/profile-forms";

@import "partials/sections/account/my-account-dashboard/dashboard-nav";
@import "partials/sections/account/my-account-dashboard/dashboard-content";

//TEMPORARY CSS
@import "partials/temporary";

///*=====================================================================</editor-fold>=
// NEW (unless otherwise stated, order of imports doesn't matter)
///=====================================================================<editor-fold>=*/

@import "vendor/animate"; // required first
@import "vendor/remixicon"; // required first
@import "vendor/sf-adjustments"; // required first

.sf-wrap {
	max-width: $sf-wrap--max-w;

	margin: 0 auto;
	width: 100%;
	padding: 0 $pad--min;
	@include respond-to-min(md) {
		padding: 0 $pad--small;
	}
	@each $bp, $px in $breakpoints {
		&.sf-wrap--no-pad-under-#{$bp} {
			@include respond-to-max($bp) {
				padding: 0;
			}
		}
		&.sf-wrap--no-pad-over-#{$bp} {
			@include respond-to-min($bp) {
				padding: 0;
			}
		}
	}
}

.sf-block {
	margin: $block-spacing-v--sm 0;
	&--no-margin-bottom {
		margin: $block-spacing-v--sm 0 0;
	}
	&--no-margin-top {
		margin: 0 0 $block-spacing-v--sm;
	}

	@include respond-to-min(md) {
		margin: $block-spacing-v--md 0;
		&--no-margin-bottom {
			margin: $block-spacing-v--md 0 0;
		}
		&--no-margin-top {
			margin: 0 0 $block-spacing-v--md;
		}
	}

	&.sf-wrap {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	&--pad-h {
		padding: 0 $pad--min;
	}
}

.sf-pad {
	@include sf-pad($pad--min);
	&.sf-pad--small {
		@include sf-pad($pad--small);
	}
	&.sf-pad--large {
		@include sf-pad($pad--large);
	}
	&.sf-pad--none {
		@include sf-pad(0);
	}
	&.sf-pad--adjustable {
		@include sf-pad($pad--small);
		@include respond-to-min(xl) {
			@include sf-pad($pad--large);
		}
	}
}

.sf-marg {
	@include sf-marg($pad--min);
	&.sf-marg--small {
		@include sf-marg($pad--small);
	}
	&.sf-marg--large {
		@include sf-marg($pad--large);
	}
	&.sf-marg--none {
		@include sf-marg(0);
	}
}

.sf-bg {
	display: flow-root;
	&--white {
		background-color: #fff;
	}

	&--grey {
		background-color: $sf-bg--grey;
		&.sf-bg--dark {
			background-color: $sf-bg--dark-grey;
		}
	}

	&--orange {
		background-color: $sf-orange--light;
		&.sf-bg--dark {
			background-color: $sf-orange--dark;
		}
	}

	&--blue {
		background-color: $sf-blue--light;
		&.sf-bg--dark {
			background-color: $sf-blue--dark;
		}
	}

	&--green {
		background-color: $sf-green;
		&.sf-bg--dark {
			background-color: $sf-green--dark;
		}
	}

	&--frog {
		@include frog-wallpaper(true);
	}

	&--checkout {
		background-color: $checkout-light-grey;
	}
}

.sf-width {
	margin-left: auto;
	margin-right: auto;
	&.sf-width {
		@for $i from 1 through 10 {
			$n: $i * 10; // 10 - 100
			&--#{$n} {
				width: $n * 1%; // 10 - 90%
			}
		}
	}
}
.sf-max-width {
	margin-left: auto;
	margin-right: auto;
	@for $i from 1 through 20 {
		$n: $i * 100; // 100 - 2000
		&.sf-max-width {
			&--#{$n} {
				max-width: $n * 1px; // 100 - 2000px
			}
		}
	}
}
.sf-min-width {
	margin-left: auto;
	margin-right: auto;
	@for $i from 1 through 20 {
		$n: $i * 100; // 100 - 2000
		&.sf-min-width {
			&--#{$n} {
				min-width: $n * 1px; // 100 - 2000px
			}
		}
	}
}

.sf-standard-typography .sf-color,
.sf-color {
	color: $sf-green;

	&.sf-color--orange {
		color: $sf-orange--light;
		&.sf-color--dark {
			color: $sf-orange--dark;
		}
	}

	&.sf-color--blue {
		color: $sf-blue--light;
		&.sf-color--dark {
			color: $sf-blue--dark;
		}
	}

	&.sf-color--white {
		color: #fff;
	}
}

%sf-block-shadow {
	box-shadow: $sf-block-shadow;
	@include respond-to-min(md) {
		box-shadow: $sf-block-shadow--md;
	}
}
.sf-block-shadow {
	@extend %sf-block-shadow;
}

.sf-shadow {
	box-shadow: $sf-shadow;
}

.sf-avatar {
	@include avatar();
}

// float BEM classes:
.sf-float {
	float: left; // default to left
	&.sf-float--right {
		float: right; // set to right with modifier class
	}
	@each $bp, $value in $breakpoints {
		&.sf-float--over-#{$bp} {
			float: none; // unset (override) any previous float value with mobile first approach
			@include respond-to-min($bp) {
				float: left; // set the float left above the current breakpoint
				&.sf-float--right {
					float: right; // set the float value right if specified with modifier class
				}
			}
		}
		&.sf-float--under-#{$bp} {
			@include respond-to-min($bp) {
				float: none; // unset (override) any previous float value once the breakpoint is reached
				&.sf-float--right {
					float: none; // ensure unset on right if specified too
				}
			}
		}
	}
}

.sf-form {
	background-color: $checkout-light-grey;
	// TEMPORARY for /my-account/ (all forms should be grey in future)
	&.sf-form--white {
		background-color: #fff;
	}

	.form-row {
		@include checkoutFormRow();
	}

	button[type="submit"],
	button[type="submit"] ~ .pseudo-submit {
		@include pill-btn--fill($sf-green, #fff);
	}

	&--shadow {
		box-shadow: $sf-shadow;
	}

	&__error {
		border-left: 3px solid $sf-color--error;
		padding: $pad--min;
		margin-bottom: $pad--min;
		overflow: hidden;

		p {
			@include p();

			a {
				@include link($sf-orange--light, $sf-orange--dark, "on-hover");
				font-weight: $sf-weight--heavy;
			}
		}
	}
}

@import "partials/plugin-overrides/contact-form-7";
@import "partials/plugin-overrides/email-octopus";
@import "partials/plugin-overrides/recaptcha-pro";
@import "partials/plugin-overrides/woocommerce";
@import "partials/plugin-overrides/wp-pagenavi";

@import "partials/components/sf-header";

@import "partials/components/navs/sf-homepage-services-nav";
@import "partials/components/navs/sf-hero-nav";
@import "partials/components/navs/sf-main-nav";
@import "partials/components/navs/sf-mobile-nav";

@import "partials/components/sf-searchbar";
@import "partials/components/sf-subscribe";

@import "partials/components/layout/sf-hide";
@import "partials/components/layout/sf-nested-cols";
@import "partials/components/layout/sf-lateral-layout";

@import "partials/components/inline/sf-arrow-link";
@import "partials/components/inline/sf-badge";
@import "partials/components/inline/sf-breadcrumbs";
@import "partials/components/inline/sf-client-square";
@import "partials/components/inline/sf-hint-help";
@import "partials/components/inline/sf-inline-download";
@import "partials/components/inline/sf-issues-key";
@import "partials/components/inline/sf-offerings";
@import "partials/components/inline/sf-operating-system";
@import "partials/components/inline/sf-post-preview";
@import "partials/components/inline/sf-post-table";
@import "partials/components/inline/sf-price";
@import "partials/components/inline/sf-quotation";
@import "partials/components/inline/sf-social-share";

@import "partials/components/sidebar/nav/sf-scroll-to-nav";
@import "partials/components/sidebar/nav/sf-related-issues";
@import "partials/components/sidebar/nav/sf-related-posts-nav";
@import "partials/components/sidebar/nav/sf-refine-search-nav";
@import "partials/components/sidebar/sf-sidebar-contact";
@import "partials/components/sidebar/sf-sidebar-social";
@import "partials/components/sidebar/sf-sidebar-software";

@import "partials/components/banners/sf-agency-overview";
@import "partials/components/banners/sf-banner-return";
@import "partials/components/banners/sf-hero";
@import "partials/components/banners/sf-homepage-hero";
@import "partials/components/banners/sf-purchase-download";
@import "partials/components/banners/sf-software-overview";

@import "partials/components/blocks/sf-accreditations";
@import "partials/components/blocks/sf-author-info";
@import "partials/components/blocks/sf-awards";
@import "partials/components/blocks/sf-block-banner";
@import "partials/components/blocks/sf-contact";
@import "partials/components/blocks/sf-coverage";
@import "partials/components/blocks/sf-carousel";
@import "partials/components/blocks/sf-passage";
@import "partials/components/blocks/sf-portfolio-clients";
@import "partials/components/blocks/sf-portfolio-content";
@import "partials/components/blocks/sf-portfolio-work";
@import "partials/components/blocks/sf-recruit";
@import "partials/components/blocks/sf-results";
// SF TODO: remove once sf-offerings is fully integrated:
@import "partials/components/blocks/sf-service-offerings";
@import "partials/components/blocks/sf-services-menu";
@import "partials/components/blocks/sf-team";
@import "partials/components/blocks/sf-testimonial";
//only needed until new user guides are in place:
@import "partials/components/blocks/sf-user-guide-menu";

@import "partials/custom-woocommerce-templates/myaccount/form-login.scss";

@import "partials/pages/sf-adwords-history";
@import "partials/pages/sf-careers";
@import "partials/pages/sf-training";
@import "partials/pages/sf-tutorials";
@import "partials/pages/sf-screaming-frog-alternatives";
@import "partials/pages/singles/sf-single-spider-issues";

@import "partials/components/overlays/modals/sf-modal";
@import "partials/components/overlays/modals/sf-download-dialog";
@import "partials/components/overlays/modals/sf-contact-dialog";
@import "partials/components/overlays/modals/sf-team--dialog";
@import "partials/components/overlays/sf-lightbox";
@import "partials/components/overlays/sf-overlay-back-to-top";

@import "partials/components/forms/upload-input";
@import "partials/components/forms/service-required";

@import "partials/helpers";

@import "partials/sections/account/no-orders.scss";

body:after {
	content: "v5.4.";
	display: flex;
	justify-items: center;
	justify-content: center;
	align-items: center;
	align-content: center;
	position: fixed;
	bottom: 0;
	right: 100%;
	background: $sf-green;
	color: #fff;
	padding: 10px;
	visibility: hidden;
	pointer-events: none;
	// display: none;
}
