.product-add-to-cart {
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	padding: $pad--min 0;

	&__title {
		white-space: nowrap;
		font-size: 20px;
		font-weight: $sf-weight--light;
		margin: $pad--min 0;
		padding: 0;
		color: $sf-font-grey;
		width: 100%;
	}

	&__quantity {
		width: 100%;
		&-control {
			$quantity-control--btn-shift: 18px;

			display: inline-flex;
			justify-content: center;

			input,
			&-btn {
				color: $currency-and-quantity-inputs--color;
				border: 1px solid $currency-and-quantity-inputs--color;
				background-color: #fff;
				text-align: center;
				padding: $currency-and-quantity-inputs--pad--v $currency-and-quantity-inputs--pad--h;
				line-height: $currency-and-quantity-inputs--line-height;
				font-size: $currency-and-quantity-inputs--font-size;
				font-weight: $currency-and-quantity-inputs--font-weight;
			}

			input {
				border-radius: 1000px;

				/* Chrome, Safari, Edge, Opera */
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				/* Firefox */
				&[type="number"] {
					-moz-appearance: textfield;
				}

				position: relative;
				z-index: 1;
			}

			&-btn {
				transition: all 0.3s;
				cursor: pointer;

				&:hover {
					background-color: $sf-green--dark;
					border-color: $sf-green--dark;
					color: #fff;
				}

				&[disabled] {
					opacity: 0.5;
					pointer-events: none;
				}

				&--increase {
					@include round-corners(1000px, "right");
					position: relative;
					right: $quantity-control--btn-shift;
					padding-left: $currency-and-quantity-inputs--pad--h +
						calc($quantity-control--btn-shift / 2);
				}
				&--decrease {
					@include round-corners(1000px, "left");
					position: relative;
					left: $quantity-control--btn-shift;
					padding-right: $currency-and-quantity-inputs--pad--h +
						calc($quantity-control--btn-shift / 2);

					span {
						position: relative;
						bottom: 2px;
					}
				}
			}
		}
	}

	&__currency {
		width: 100%;
	}
	&__price {
		width: 100%;

		.price {
			font-size: 45px;
			line-height: 42px;
			font-weight: 900;
			color: $sf-font-grey;
			white-space: nowrap;

			&.calculating {
				font-size: 18px;
			}
		}

		@include respond-to-max(sm) {
			&--per-user {
				display: none;
			}
		}
	}
	&__submit {
		padding: $pad--min 0;
		width: 100%;

		button {
			@include pill-btn--fill($bg: $sf-green, $elongate: 4);
			font-size: $currency-and-quantity-inputs--font-size;
			font-weight: $currency-and-quantity-inputs--font-weight;
			white-space: nowrap;
		}
	}

	@include respond-to-min(sm) {
		&__quantity,
		&__currency,
		&__price {
			width: 50%;
		}

		@include respond-to-max(md) {
			&__quantity {
				order: 0;
			}
			&__currency,
			&__price,
			&__submit {
				order: 1;
			}
		}
		@include respond-to-min(md) {
			padding-bottom: $pad--min * 2;

			&__quantity,
			&__currency,
			&__price,
			&__submit {
				width: 25%;
			}

			&__submit {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-content: center;
				align-items: center;
				padding: 0;

				button {
					@include pill-btn--fill($bg: $sf-green, $elongate: 4, $padding: 10px);
					width: 100%;
					max-width: 180px;
				}
			}

			@include respond-to-max(lg) {
				&__price {
					&--per-user {
						display: none;
					}
				}
			}

			@include respond-to-min(lg) {
				&__quantity,
				&__currency,
				&__price,
				&__submit {
					width: 20%;
				}
			}
		}
	}
}
