.sf-message {
	@include notification-message(3px solid $sf-font-grey);
	&--error {
		border-left-color: $sf-color--error;
	}
	&--warning {
		border-left-color: $sf-color--warning;
	}
	&--success {
		border-left-color: $sf-color--success;
	}
	p {
	}
}
