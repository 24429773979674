.wrap-account {
	.woocommerce-address-fields {
		margin-top: 20px;
	}

	.col-1.woocommerce-Address {
		background-color: #fff;
		padding: 20px;
		box-sizing: border-box;
		border: 1px solid #e6e6e6;
	}

	.woocommerce-Address a.edit {
		@include account-edit-btn();
		&-tax {
			margin-top: 5px;
			@include account-edit-btn(false);
		}
	}

	.woocommerce-Address,
	.woocommerce-account .addresses address {
		font-size: 16px;
		line-height: 1.4;
		font-style: normal;
	}

	.col-1.woocommerce-Address .woocommerce-Address-title.title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;

		h3 {
			font-size: 18px;
			text-transform: capitalize;
		}
	}

	// .woocommerce .wrap-account .col-1.woocommerce-Address .woocommerce-Address-title.title:before,
	// .woocommerce .wrap-account .col-1.woocommerce-Address .woocommerce-Address-title.title:after {
	// 	display: none;
	// }

	// .woocommerce .wrap-account .col-1.woocommerce-Address .woocommerce-Address-title.title .edit {
	// 	margin-left: 20px;
	// }
}
