//@import "../config/config";

///*=====================================================================</editor-fold>=
// ERRORS
///=====================================================================<editor-fold>=*/

.error-wrap {
	@include frog-wallpaper();
	padding: 190px 20px;
	display: table;
	width: 100%;
}

.error-wrap--inner {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	font-weight: $sf-weight--light;
}

.error-wrap--inner h1 {
	color: #fff;
	font-size: 170px;
	line-height: 110px;
	margin-bottom: 15px;
	font-weight: $sf-weight--light;
}

.error-wrap--inner p {
	color: #d1ff9b;
	max-width: 665px;
	margin: 0 auto;
	line-height: 28px;
}

.woocommerce-message {
	@include notification-message($success-border);
}
.woocommerce-error {
	@include notification-message();
}
.woocommerce-error,
.woocommerce-message {
	border-top: none !important;
	background-color: transparent !important;
	margin: 10px !important;
	padding: $pad--min !important;
	animation-duration: 1s;
	animation-name: fade-in;

	li {
		animation-duration: 1s;
		animation-name: fade-in-left;
	}

	&::before {
		display: none !important;
	}
}

//</editor-fold>
