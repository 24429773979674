// Colours

$white: #fff;
$mindaro: #c1fa82;
$sushi: #74b42d;
$dove-grey: #707070;
$alabaster: #f8f8f8;
$conflower-blue: #6993f2;
$royal-blue: #3a71ee;
$mercury: #e2e2e2;
$grey: #888;
$black: #4c4c4c;
$lima: #7ac71f;
$silver: #c9c9c9;
$apple: #5e9a3c;
$bittersweet: #ff6565;
$scorpion: #5d5d5d;
$willow-grove: #676966;
$loafer: #eef5e6;
$mine-shaft: #333333;
$flint: #767469;
$reef: #d1ff9b;
$table-border-color: #e1e1e1;

$screaming-green: $sushi;
$screaming-light-green: $mindaro;
$screaming-dark-green: $apple;
$screaming-light-grey: $alabaster;
$screaming-dark-grey: $mercury;
$screaming-blue: $conflower-blue;
$screaming-dark-blue: $royal-blue;
$font-color-default: $grey;
$font-color-medium: $grey;
$screaming-brand-black: $black;
$screaming-brand-green: $lima;
$color-accent1: $silver;
$color-accent3: $willow-grove;
$color-accent4: $loafer;
$color-accent5: $reef;
$color-training-btn-sold-out: $apple;
$color-sold-out-label: $bittersweet;

//SF NEW
$sf-grey--dark: #41433f;
$sf-grey: #2b2a2a;
$sf-grey--light: #949698;
$sf-grey--lighter: #d6d6db;
$sf-grey--lightest: #f8f8f8;

$sf-green--lighter: #d1ff9b;
$sf-green--light: #9bca27;
$sf-green: #75b42d;
$sf-green--dark: #5c941c;
$sf-green--darker: #50801a;

$sf-orange--light: #ffbc2b;
$sf-orange--dark: #eb8a23;

$sf-border--grey: $sf-grey--lighter;
$sf-bg--grey: $sf-grey--lightest;
$sf-bg--dark-grey: $sf-grey--lighter;

$sf-color--error: #ff6565;
$sf-color--warning: $sf-orange--light;
$sf-color--success: $sf-green--light;

$sf-blue--light: #7180e4;
$sf-blue--dark: #3f52d5;

$sf-font-grey: $sf-grey;
$sf-nav-grey: #585855;
// $sf-nav-grey: $sf-grey--dark;
// $sf-font-grey--light: $sf-grey--light;

$sf-table-bg-grey: $sf-grey--lightest;

//Learn SEO
$learnSEO-green: #83b145;
$learnSEO-orange: #fbb92f;
$learnSEO-blue: #6d7bba;
$link-color: #5c5c5c;

//issues pages
$issues-blue: #6f7ab8;
$issues-yellow: #f9bb2f;
$issues-red: #c12926;

$social-colors: (
	email: (
		light: #0a88ff,
		dark: #006ed6
	),
	facebook: (
		light: #306199,
		dark: #244872
	),
	tumblr: (
		light: #32506d,
		dark: #22364a
	),
	linkedin: (
		light: #007bb6,
		dark: #005983
	),
	twitter: (
		light: #26c4f1,
		dark: #0eaad6
	),
	googleplus: (
		light: #e93f2e,
		dark: #ce2616
	),
	youtube: (
		light: #df1c31,
		dark: #b21627
	),
	reddit: (
		light: #8bbbe3,
		dark: #62a3d9
	),
	pinterest: (
		light: #b81621,
		dark: #8a1119
	),
	pocket: (
		light: #ed4054,
		dark: #e4162d
	),
	github: (
		light: #444,
		dark: #2b2b2b
	),
	instagram: (
		light: #517fa4,
		dark: #406582
	),
	delicious: (
		light: #0b79e5,
		dark: #095fb4
	),
	vk: (
		light: #4d71a9,
		dark: #3d5a86
	),
	hackernews: (
		light: #f60,
		dark: #cc5200
	)
);
